<template>
  <div class="footer">
    <div class="w">
      <div class="footer-inner">
        <div class="footer-info">
          <div class="footer-links">
            <div class="footer-links-item footer-link-contact">
              <!-- <h6>{{ $t('footer.ecological') }}</h6> -->
              <ul class="footer-links-ecological">
                <li class="footerlogo">
                  <img src="@/assets/static/ngklogo.png" alt="">
                  <!-- NGK Explorer -->
                </li>
                <li class="footerItem1box1 leftone">MEPAY</li>
                <li class="footerItem1box1 leftone">Token Economy 3.0</li>
                <li class="footerItem1box1 leftone">Global Launch</li>
              </ul>
            </div>
            <div class="footer-links-item">
              <h6
                style="padding-top:4px;padding-bottom:33px;font-size:21px"
              >{{ $t('newfooter.title1') }}</h6>
              <ul class="footer-links-item2">
                <li>
                  <div style="display:block;width:100%;height:50px;" class="footerimgBox">
                    <a href="javascript:void(0);" style="display:block">
                      <div class="imgBox2">
                        <img src="@/assets/static/footerpic2.png" alt="" class="pic2">
                        <img src="@/assets/static/showfooterpic2.png" alt="" class="showpic2">
                      </div>
                    </a>
                    <a href="javascript:void(0);" style="display:block">
                      <div class="imgBox3">
                        <img src="@/assets/static/footerpic3.png" alt="" class="pic3">
                        <img src="@/assets/static/showfooterpic3.png" alt="" class="showpic3">
                      </div>
                    </a>
                    <a href="javascript:void(0);" style="display:block">
                      <div class="imgBox4">
                        <img src="@/assets/static/footerpic4.png" alt="" class="pic4">
                        <img src="@/assets/static/showfooterpic4.png" alt="" class="showpic4">
                      </div>
                    </a>
                    <a href="javascript:void(0);" style="display:block;">
                      <div class="imgBox5">
                        <img src="@/assets/static/footerpic5.png" alt="" class="pic5">
                        <img src="@/assets/static/showfooterpic5.png" alt="" class="showpic5">
                      </div>
                    </a>
                  </div>
                  <div style="display:block;width:100%;height:50px" class="footerimgBox">
                    <a href="javascript:void(0);" style="display:block">
                      <div class="imgBox6">
                        <img src="@/assets/static/footerpic6.png" alt="" class="pic6">
                        <img src="@/assets/static/showfooterpic6.png" alt="" class="showpic6">
                      </div>
                    </a>
                    <a href="javascript:void(0);" style="display:block">
                      <div class="imgBox7">
                        <img src="@/assets/static/footerpic7.png" alt="" class="pic7">
                        <img src="@/assets/static/showfooterpic7.png" alt="" class="showpic7">
                      </div>
                    </a>
                    <a href="javascript:void(0);" style="display:block">
                      <div class="imgBox8">
                        <img src="@/assets/static/footerpic8.png" alt="" class="pic8">
                        <img src="@/assets/static/showfooterpic8.png" alt="" class="showpic8">
                      </div>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="footer-links-item footer-link-contact">
              <h6
                style="padding-top:4px;padding-bottom:33px;font-size:19px"
              >{{ $t('newfooter.title2') }}</h6>
              <ul class="footer-links-aboutus footer-links-item3 footer-links-item3-about">
                <li>
                  <a href="javascript:void(0);" style="display:block;">{{ $t('newfooter.text1') }}</a>
                </li>
                <li>{{ $t('newfooter.text2') }}</li>
              </ul>
            </div>
            <div class="footer-links-item footer-link-contact">
              <h6
                style="padding-top:4px;padding-bottom:33px;font-size:21px"
              >{{ $t('footer.about') }}</h6>
              <ul class="footer-links-aboutus footer-links-item4">
                <li>
                  <a href="javascript:void(0);" id="supportmail">
                    {{ $t('footer.support') }}:
                    <span class="footer-support-email">sdhcsdn@gmail.com</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    {{ $t('footer.cooperation') }}:
                    <span class="footer-support-email">sdhcsdn@gmail.com</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="footer-sidebar">
            <div class="footer-service">{{ $t('footer.community') }}</div>
            <div class="footer-codes">
              <div class="share-list isPc">
                <a href="#" target="_blank" class="share-item">
                  <i class="iconfont iconfabu"></i>
                </a>
                <a href="#" target="_blank" class="share-item">
                  <i class="iconfont icontwitter"></i>
                </a>
                <a href="#" target="_blank" class="share-item">
                  <i class="iconfont iconFacebook"></i>
                </a>
                <a href="#" target="_blank" class="share-item">
                  <i class="iconfont iconmedium"></i>
                </a>
                <a href="#" target="_blank" class="share-item">
                  <i class="iconfont iconxinlangweibo"></i>
                </a>
              </div>
              <div class="share-list isMobile">
                <a href="#" target="_blank" class="share-item">
                  <i class="iconfont iconfabu"></i>
                </a>
                <a href="#" target="_blank" class="share-item">
                  <i class="iconfont icontwitter"></i>
                </a>
                <a href="#" target="_blank" class="share-item">
                  <i class="iconfont iconFacebook"></i>
                </a>
                <a href="#" target="_blank" class="share-item">
                  <i class="iconfont iconmedium"></i>
                </a>
                <a href="#" target="_blank" class="share-item">
                  <i class="iconfont iconxinlangweibo"></i>
                </a>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style scoped lang="less">
.share-list.isMobile {
  .share-item {
    background: #5863fc;
    color: #fff;
  }
}

.footer-support-email {
  // color: #5863fc;
}

@media (max-width: 768px) {
  .footer-links-ecological li {
    margin-right: 15px;
  }

  .footer-links-ecological li:last-child {
    margin-right: 0;
  }

  .footer-links-aboutus {
    display: flex;
    flex-flow: column;
  }
}
</style>
