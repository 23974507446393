/*import Vue from 'vue'*/
//import VueI18n from 'vue-i18n'
//import Cookies from 'js-cookie'
import usLocale from './en'
import cnLocale from './zh-CN'
import twLocale from './zh-TW'
import koLocale from './ko'
import jaLocale from './ja'
import ptLocale from './pt'
import ruLocale from './ru'
import deLocale from './de'
import esLocale from './es'
import arLocale from './ar'
import frLocale from './fr'
import khLocale from './kh'
import thLocale from './th'
import vnLocale from './vn'

/*Vue.use(VueI18n)*/

const messages = {
  'en': {
    ...usLocale
  },
  'zh-cn': {
    ...cnLocale
  },
  'zh-tw': {
    ...twLocale
  },
  'ko': {
    ...koLocale
  },
  'ja': {
    ...jaLocale
  },
  'pt': {
    ...ptLocale
  },
  'ru': {
    ...ruLocale
  },
  'de': {
    ...deLocale
  },
  'fr': {
    ...frLocale
  },
  'es': {
    ...esLocale
  },
  'ar': {
    ...arLocale
  },
  'kh': {
    ...khLocale
  },
  'th': {
    ...thLocale
  },
  'vn': {
    ...vnLocale
  },
}
// console.log(Cookies.get('language'))
/*const i18n = new VueI18n({
  locale: Cookies.get('language') || 'zh-tw',
  messages
})*/

export default messages
