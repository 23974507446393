export default {
  common: {
    copy: '写す',
    copySuccessMessage: 'コピー成功',
    copyFailedMessage: 'ブラウザは自動コピーをサポートしていません'
  },
  navbar: {
    homePage: 'ホーム',
    contracts: 'スマート契約',
    tokens: 'トークン情報',
    blocks: 'ブロック情報',
    generateKey: '鍵の生成'
  },
  footer: {
    ecological: 'xxx',
    ex: 'xxx',
    game: 'xxx',
    sto: 'xxx',
    bank: 'xxx',
    capital: 'xxx',
    techSupport: '技術サポート',
    lab: 'xxx技術研究所',
    explorer: 'Blockchainブラウザ',
    wallet: 'ウォレットダウンロード',
    help: 'ヘルプセンター',
    about: '私たちについて',
    group: 'xxxグループ',
    support: 'サポートヘルプ',
    cooperation: '事業協力',
    community: 'xxxコミュニティ'
  },
  lang: {
    en: 'English',
    cn: '简体中文',
    ko: '한국어',
    tw: '中文繁体',
    ja: '日本語',
    pt: 'Português',
    ru: 'русский',
    es: 'Español',
    fr: 'français',
    de: 'Deutsche',
    ar: 'عربى',
    th: 'ไทย',
    vn: 'ViệtName',
    kh: 'ជនជាតិខ្មែរ'
  },
  search: {
    inputPlaceholder: '検索ブロック/アカウント/Trx Hash',
    errorMessage: 'エラー：APIエラー：不明なブロック：不明なトランザクション：不明なアカウント'
  },
  main: {
    producer: 'ブロックノード',
    headBlock: 'ブロック高',
    tps: '現在/最高TPS',
    txs: 'トランザクション数',
    transactions: '最新の取引履歴',
    producers: 'スーパーノード',
    applyJoin: 'スーパーノードへの参加を申し込む',
    rank: 'ランク',
    nodeArea: '国/地域',
    votes: 'ウェイト/投票率',
    nodeName: '名前',
    blockId: '最新のブロックHash',
    applyJoinErrorMessage: 'この機能はまだ開いていないため、しばらくお待ちください'
  },
  blocks: {
    blocks: 'ブロックリスト',
    block: 'ブロック',
    blockId: 'ブロックHash',
    txs: 'トランザクション数',
    actions: 'オペランド',
    producer: '発生器',
    produceTime: '生成時間'
  },
  blockDetail: {
    transactions: '取引記録'
  },
  transactionDetail: {
    detail: '取引詳細',
    actions: 'Actions',
    raw: '生データ',
    txId: '取引Hash',
    blockId: 'ブロックHash',
    prevBlockId: '前のブロックHash',
    headBlock: 'ブロック',
    producer: 'ブロックノード',
    createTime: 'タイムスタンプ',
    action: '契約方法',
    from: '譲渡人',
    to: '受信機',
    quantity: '量',
    memo: '備考'
  },
  detail: {
    block: {
      blockDetail: 'ブロックの詳細',
      blockId: 'ブロックHash',
      prevBlockId: '前のブロックHash',
      headBlock: 'ブロック高',
      txs: 'レコード数',
      producer: 'ブロックノード',
      createTime: 'タイムスタンプ'
    },
    transaction: {
      trxId: 'Trx Hash',
      time: '時間',
      action: 'Action',
      data: 'データ'
    }
  },
  account: {
    created: '内蔵',
    resources: '资产',
    available: '利用可能残高',
    refunding: '償還',
    tokens: 'トークンの保持',
    transactions: '取引記録',
    detail: 'アカウント詳細',
    name: '名前',
    createdTime: '作成時間',
    mainTokenBalance: '主な通貨残高',
    totalAsset: '総価値',
    owner: 'Owner 公開鍵',
    active: 'Active 公開鍵'
  },
  404: {
    message: '入力したURLが正しいか確認してください。下のボタンをクリックしてホームページに戻るか、エラーレポートを送信してください',
    goHome: 'トップに戻る'
  },
  error: {
    message: 'MEPAY Explorerは、ブロックの高さ、ブロックのハッシュ、口座番号、取引など、チェーン上の情報の検索に対応していますので、入力内容が正しいことを確認してから、再度検索することをお勧めします。'
  },
  tokens: {
    tokensTitle: 'Token生態学',
    update: '更新日',
    totalToken: '電流Token量',
    searchToken: '検索するToken',
    name: 'Token名前',
    issuerName: '発行者',
    supply: '流動性',
    maxSupply: '総発行',
    toDetail: '見る',
    tokenDetails: 'Token細部',
    transactions: '取引記録',
    tokenRank: 'Token ランク',
    rank: 'ランク',
    account: 'ユーザ名',
    balance: '残高',
    percentage: '割合',
    tokenAccountsTotal: 'トークン保有アカウントの現在の数',
    tokenAccountPercentageTitle: 'トークン保有口座の分配'
  },
  contracts: {
    name: '契約名',
    totalActiveAccountCount: '24h アクティブなアカウント',
    totalCallCount: '24h 呼び出し',
    actions: '契約方法',
    lastUpdateTime: '最終展開日',
    ycxjyzt: 'ワンタイムチェック状態',
    dmsjzt: 'コード監査ステータス'
  },
  actions: {
    issue: {
      operate: '分散システム',
      to: '与える',
      dailyissue: '日刊'
    },
    activate: {
      operate: '招待アカウント'
    }
  },
  banner: {
    title: 'MEPAY ',
    titleen: 'Blockchain Explorer',
    text: 'MEPAYは分散アプリケーションプログラムです。ブロックプロデューサーは、コミュニティに高速で安定した取引環境を提供するために選択されます。ブロックプロデューサーは、注目度の高い分散サーバーに接続され、ネットワークの通常の操作を提供し、MEPAYに参加します。 独自のスマートコントラクトを実行するコードを記述できます'
  },
  newfooter: {
    title1: 'MEPAYコミュニティ',
    title2: '技術サポート',
    title3: '私たちに関しては',
    text1: 'Blockchainソースコード',
    text2: 'ウォレットダウンロード',
    text3: 'サポートヘルプ：',
    text4: 'ビジネス協力：'
  },
  chaoji: {
    title1: '编号',
    title2: '节点区域',
    title3: '节点ID',
    title4: '节点名称',
    title5: '区块高度',
    title6: '最新区块Hash',
    zanwu: '暂无数据'
  },
  zizhanghao: {
    text1: 'サブアカウント',
    text2: '作成時間'
  }
}
