import {createApp, showNotify} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css'
import '../src/styles/lang.scss'
import '../src/iconfont/iconfont.css'
import '../src/styles/index.scss'
import '../src/styles/custom.scss'
import '../src/icons'
import "../src/assets/font/font.css";
import 'wowjs/css/libs/animate.css'





import { createI18n } from 'vue-i18n'
import messages from './lang/index'


 
const  i18n = createI18n({
    legacy: false,
    locale: Cookies.get('language') || 'zh-tw',
    messages
})



// 剪切板
import vueclipboard from 'vue-clipboard2'
vueclipboard.config.autoSetContainer = true


import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


import Cookies from "js-cookie";

import signalr from './utils/signalR'

const Vue = createApp(App)

    .use(vueclipboard)
    .use(signalr)
    .use(i18n).use(store).use(router)
    .use(ElementPlus)

import common from './utils/common'

Vue.config.globalProperties.$utils = common
//在main.js引入
Vue.mount('#app')

