const CURRENT_ENV = process.env.NODE_ENV === 'production' ? 2 : 0

const DEVUrl = {
  ServiceBase: 'http://13.212.121.102:55500/Explorer'
}

const PRODUrl = {
  ServiceBase: 'http://13.212.121.102:55500/Explorer'
}

const BASE = CURRENT_ENV == 0 ? PRODUrl : PRODUrl
export const Url = {
  serviceBase: BASE.ServiceBase,
  homeStatistical: BASE.ServiceBase + '/Block/HomeStatistical',
  homeTransactionListLatest: BASE.ServiceBase + '/Transaction/ListLatest',
  homeListProducers: BASE.ServiceBase + '/Block/ListProducers',
  blockListLatest: BASE.ServiceBase + '/Block/ListLatest',
  blockDetail: BASE.ServiceBase + '/Block/GetDetail',
  detailListTransaction: BASE.ServiceBase + '/Transaction/ListTransaction',
  getRawData: BASE.ServiceBase + '/Transaction/GetRawData',
  getTokenList: BASE.ServiceBase + '/Token/GetTokenList',
  getTokenInfo: BASE.ServiceBase + '/Token/GetTokenDetails',
  getTokenAccounts: BASE.ServiceBase + '/Token/GetTokenAccounts',
  getTokenAccountsPie: BASE.ServiceBase + '/Token/GetTokenAccountsPieData',
  searchQuery: BASE.ServiceBase + '/Search/Query',
  queryContract: BASE.ServiceBase + '/Contract/Query',
  listTransactionOfAccount: BASE.ServiceBase + '/Transaction/ListOfAccount',
  listTokensOfAccount: BASE.ServiceBase + '/Token/GetTokensOfAccount',
  getAsset: BASE.ServiceBase + '/Account/GetAsset',
  getChildAccount: BASE.ServiceBase + '/account/getsubaccounts',
}
