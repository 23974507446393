<template>
    <div id="app" style="background:#0C0A1D">
        <header-app v-if="!isSingle" @func="getMsgFormSon"></header-app>
        <router-view :inputValue="language"></router-view>
        <footer-app v-if="!isSingle"></footer-app>
    </div>
</template>

<script>
    import footerApp from "@/views/layout/footer";
    import headerApp from "@/views/layout/header";
    // import Cookies from 'js-cookie'
    export default {
        data() {
            return {
                language: ""
            };
        },
        name: "App",
        components: {
            footerApp,
            headerApp
        },
        computed: {
            isSingle() {
                return this.$route.name === "share" ? true : false;
            }
        },
        mounted() {
            document.getElementById("appLoading").style.display = "none";

            // console.log('新包')
        },
        methods: {
            getMsgFormSon(data) {
                this.language = data;
              //   console.log(this.language);
            }
        },

    };

</script>
<style lang="less">
/*    @import "~vux/src/styles/1px.less";
    @import "~vux/src/styles/reset.less";*/
.el-loading-spinner .path {
    -webkit-animation: loading-dash 1.5s ease-in-out infinite;
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    /* stroke: var(--el-color-primary); */
    stroke-linecap: round;
    stroke: rgb(240, 228, 188) ;
}
.el-loading-spinner .el-loading-text {
    /* color: var(--el-color-primary); */
    color: rgb(240, 228, 188) ;
    margin: 3px 0;
    font-size: 14px;
}
  .el-loading-spinner i {
        color: #b9bec3 !important;
        font-size: 30px;
    }
    div,
    p {
        font-family: "思源黑体";
    }
    .el-loading-spinner .el-loading-text {
        color: #b9bec3 !important;
        margin: 3px 0;
        font-size: 14px;
    }
    .el-loading-mask {
        bottom: -5px !important;
        background: rgba(0, 0, 0, 0.5);
    }
    /* 加载框。。。样式修改 */
    .el-loading-spinner {
        width: 80px !important;
        height: 80px !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 8px;
        left: 50%;
        transform: translateX(-50%);
    }
</style>
