import {createApp} from 'vue'
import App from '../App'
import SvgIcon from '@/components/SvgIcon'

const app2 = createApp(App)

//Vue.component('svg-icon', SvgIcon)
app2.component('svg-icon', SvgIcon)

const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('./svg', false, /\.svg$/)
requireAll(req)
