import Cookies from 'js-cookie'

const app = {
  state: {
    language: Cookies.get('language') || 'zh-tw',
    langIndex: Cookies.get('langIndex') || 0
  },
  mutations: {
    SET_LANGUAGE: (state, language) => {
      state.language = language
      Cookies.set('language', language)
    },
    SET_LANGUAGE_INDEX: (state, index) => {
      state.langIndex = index
      Cookies.set('langIndex', index)
    },
    SET_COIN_TYPE: (state, type) => {
      state.cointype = type
      Cookies.set('cointype', type)
    }
  },
  actions: {
    setLanguage({
      commit
    }, language) {
      commit('SET_LANGUAGE', language)
    },
    setLangIndex({
      commit
    }, index) {
      commit('SET_LANGUAGE_INDEX', index)
    },
    setCoinType({
      commit
    }, type) {
      commit('SET_COIN_TYPE', type)
    }
  }
}

export default app
