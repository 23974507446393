export default {
    common: {
        copy: 'cópia de',
        copySuccessMessage: 'Cópia bem sucedida',
        copyFailedMessage: 'O navegador não suporta cópia automática'
    },
    navbar: {
        homePage: 'Casa',
        contracts: 'Contrato inteligente',
        tokens: 'Informações do token',
        blocks: 'Bloquear informações',
        generateKey: 'Geração de chaves'
    },
    footer: {
        ecological: 'xxx',
        ex: 'xxx',
        game: 'xxx',
        sto: 'xxx',
        bank: 'xxx',
        capital: 'xxx',
        techSupport: 'Suporte técnico',
        lab: 'xxx laboratório técnico',
        explorer: 'Navegador Blockchain',
        wallet: 'Download carteira',
        help: 'Centro de ajuda',
        about: 'sobre nós',
        group: 'xxxgrupo',
        support: 'ajuda apoio',
        cooperation: 'Cooperação empresarial',
        community: 'xxxcomunidade'
    },
    lang: {
        en: 'English',
        cn: '简体中文',
        ko: '한국어',
        tw: '中文繁体',
        ja: '日本語',
        pt: 'Português',
        ru: 'русский',
        es: 'Español',
        fr: 'français',
        de: 'Deutsche',
        ar: 'عربى'
    },
    search: {
        inputPlaceholder: 'Pesquisar Bloco / Conta / Trx Hash',
        errorMessage: 'Erro: Erro de API: Bloco desconhecido: Transação desconhecida: Conta desconhecida'
    },
    main: {
        producer: 'nó bloco',
        headBlock: 'Altura do bloco',
        tps: 'TPS atual / mais alto',
        txs: 'Número de transações',
        transactions: 'última transação',
        producers: 'Supernode',
        applyJoin: 'Solicitar adesão ao super-nó',
        rank: 'Classificação',
        nodeArea: 'país / região',
        votes: 'Peso / Taxa de votos',
        nodeName: 'nome',
        blockId: 'Último bloco Hash',
        applyJoinErrorMessage: 'Esse recurso ainda não está aberto, portanto, fique atento'
    },
    blocks: {
        blocks: 'lista de bloqueio',
        block: 'Quadra',
        blockId: 'Block Hash',
        txs: 'Número de transações',
        actions: 'Operando',
        producer: 'Gerador',
        produceTime: 'Tempo de geração'
    },
    blockDetail: {
        transactions: 'transação Registro'
    },
    transactionDetail: {
        detail: 'Detalhes da transação',
        actions: 'Actions',
        raw: 'Dados não tratados',
        txId: 'Hash de negociação',
        blockId: 'Block Hash',
        prevBlockId: 'Bloco anterior Hash',
        headBlock: 'Quadra',
        producer: 'nó bloco',
        createTime: 'timestamp',
        action: 'Método de contrato',
        from: 'Transferidor',
        to: 'Receptor',
        quantity: 'Quantidade',
        memo: 'Observações'
    },
    detail: {
        block: {
            blockDetail: 'detalhes Bloco',
            blockId: 'Block Hash',
            prevBlockId: 'Bloco anterior Hash',
            headBlock: 'Altura do bloco',
            txs: 'Número de registros',
            producer: 'Nó de bloco',
            createTime: 'timestamp'
        },
        transaction: {
            trxId: 'Trx Hash',
            time: 'Tempo',
            action: 'Action',
            data: 'dados'
        }
    },
    account: {
        created: 'Integrado',
        resources: 'ativos',
        available: 'Saldo disponível',
        refunding: 'Resgatando',
        tokens: 'Guardando fichas',
        transactions: 'transação Registro',
        detail: 'Detalhes da conta',
        name: 'nome',
        createdTime: 'Tempo de criação',
        mainTokenBalance: 'Saldo da moeda principal',
        totalAsset: 'valor total',
        owner: 'Owner Chave pública',
        active: 'Active Chave pública'
    },
    404: {
        message: 'Verifique se o URL digitado está correto, clique no botão abaixo para retornar à página inicial ou enviar um relatório de erro',
        goHome: 'De volta ao topo'
    },
    error: {
        message: 'O NGK Explorer suporta a pesquisa de informações na cadeia, como altura do bloco, hash do bloco, número da conta e transações.Recomenda-se que você tente pesquisar novamente depois de confirmar que o conteúdo de entrada está correto.'
    },
    tokens: {
        tokensTitle: 'ecologia token',
        update: 'Atualizado em',
        totalToken: 'Número atual de tokens',
        searchToken: 'Token de pesquisa',
        name: 'Nome do token',
        issuerName: 'Emissor',
        supply: 'Liquidez',
        maxSupply: 'Emissão total',
        toDetail: 'Visão',
        tokenDetails: 'TokenDetalhes',
        transactions: 'transação Registro',
        tokenRank: 'Token Posição',
        rank: 'Posição',
        account: 'nome do usuário',
        balance: 'Saldo',
        percentage: 'Proporção',
        tokenAccountsTotal: 'Número atual de contas de depósito de tokens',
        tokenAccountPercentageTitle: 'Distribuição de contas de depósito de tokens'
    },
    contracts: {
        name: 'Nome do contrato',
        totalActiveAccountCount: '24h Contas ativas',
        totalCallCount: '24h Chamadas',
        actions: 'Método de contrato',
        lastUpdateTime: 'Última data de implantação',
        ycxjyzt: 'Status de verificação única',
        dmsjzt: 'Status de auditoria do código'
    },
    actions: {
        issue: {
            operate: 'O sistema distribuiu',
            to: 'dar',
            dailyissue: 'Edição diária'
        },
        activate: {
            operate: 'Conta de convite'
        }
    },
    banner: {
        title: 'MEPAY ',
        titleen: 'Blockchain Explorer',
        text: 'O NGK.IO é um programa de aplicativo distribuído. Os produtores de blocos serão selecionados para fornecer um ambiente de negociação rápido e estável para a comunidade.Os produtores de blocos acessarão servidores distribuídos de ponta para fornecer operações normais para a rede. Você pode escrever código no .IO para executar seu próprio contrato inteligente!'
    },
    newfooter: {
        title1: 'Comunidade NGK',
        title2: 'Suporte técnico',
        title3: 'sobre nós',
        text1: 'código-fonte Blockchain',
        text2: 'Download carteira',
        text3: 'ajuda Apoio:',
        text4: 'Cooperação comercial:'
    },
    chaoji: {
        title1: '编号',
        title2: '节点区域',
        title3: '节点ID',
        title4: '节点名称',
        title5: '区块高度',
        title6: '最新区块Hash',
        zanwu: '暂无数据'
    }
}

