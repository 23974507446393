import {
  Url
} from '@/api/apiconfig'
import * as signalR from '@aspnet/signalr'

const url = `http://8.210.38.52:29890/hub/last-block`
const signalr = new signalR.HubConnectionBuilder()
  .withUrl(url)
  .build()

signalr.start().then(()=>{
  console.log("连接成功")
}).catch(()=>{
  console.log("连接失败")
})

const install = Vue => {

  Vue.config.globalProperties.signalr = signalr
 /* Vue.config.globalProperties.$signalr.start().then(() => {
    console.log("自动更新连接成功");
  });*/
}
export default install

