export default {
    fixedTxID: (txID) => {
        if (!txID || txID.trim() == '') return;
        return txID.substr(0, 8)
    },
    decimal_format(number, thousands_sep = ",") {
        let sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            s = (number + '').split('.');
        let re = /(-?\d+)(\d{3})/;
        while (re.test(s[0])) {
            s[0] = s[0].replace(re, "$1" + sep + "$2");
        }
        return s.join('.');
    },
    quantity_format(quantity) {
        quantity = quantity || '';
        return quantity.split(' ');
    }
}