import {createRouter, createWebHistory, createWebHashHistory} from 'vue-router'
/* 首页页面 */
//const Home = () => import('../views/home/Home');

const Search = () => import('../views/search');
const Main = () => import('../views/main')


/*import {i18n} from "../lang/i18n"*/
import store from '../store';
import {showNotify} from "vant";


const routes = [
  {
    path: '/',
    component: Search,
    name: 'homepage',
    hidden: true,

    children: [
      {
        path: "/",
        component: Main,
      },
      {
        path: '/tokens',
        component: () => import('@/views/tokens'),
        name: 'tokens',
        meta: {
          title: 'Token生态'
        },
        hidden: true
      },
      {
        path: '/tokens/:id',
        component: () => import('@/views/tokenDetail'),
        name: 'tokenDetail',
        meta: {
          title: 'Token详情'
        },
        hidden: true
      },
      {
        path: '/contracts',
        name: 'boxredirect',
        redirect: '/contracts/all',
        meta: {
          title: '智能合约首页'
        },
        hidden: true
      },
      {
        path: '/contracts/all',
        component: () => import('@/views/contracts'),
        name: 'contractsAll',
        meta: {
          title: '智能合约'
        },
        hidden: true
      },
      {
        path: '/contracts/other',
        component: () => import('@/views/contracts'),
        name: 'contractsOther',
        meta: {
          title: '其它合约'
        },
        hidden: true
      },
      {
        path: '/blocks',
        component: () => import('@/views/blocks'),
        name: 'blockList',
        meta: {
          title: '区块信息'
        },
        hidden: true
      },
      {
        path: '/block/:id',
        component: () => import('@/views/blockDetail'),
        name: 'blockDetail',
        meta: {
          title: '区块详情'
        },
        hidden: true
      },
      {
        path: '/transaction/:id',
        component: () => import('@/views/transactionDetail'),
        name: 'transactionDetail',
        meta: {
          title: '交易详情'
        },
        hidden: true
      },
      {
        path: '/account/:id',
        component: () => import('@/views/account'),
        name: 'account',
        meta: {
          title: '账号详情'
        },
        hidden: true
      },
      {
        path: 'error',
        component: () => import('@/views/error'),
        name: 'error',
        meta: {
          title: '无内容'
        },
        hidden: true
      }
    ],

  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    name: '404',
    meta: {
      title: '404'
    },
    hidden: true
  },
  { path:"/:pathMatch(.*)", redirect: '/404', hidden: true }
]

const router = createRouter({
   // history: createWebHistory(process.env.BASE_URL),
    history:createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {


    if(to.meta.isAuthRequired && store.state.user.isLogin === false) {
      showNotify(i18n.global.t('messages.info.nologin'))
      return next()
    }else{
      next();
    }



    document.title = to.meta.title

})

export default router
